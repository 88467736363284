<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <v-card v-if="selected == null" style="margin-bottom: 30px">
          <v-card-title>
            Pending Orders
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            @click:row="clicked"
            :headers="headers"
            :items="order"
            :search="search"
            style="cursor: pointer;"
          ></v-data-table>
        </v-card>

        <div v-if="selected">
          <OrderInfo :idOrder="selected.order_id"></OrderInfo>
 
          <b-card class="mt-3" header="Payment" style="margin-bottom: 20px">
            <b-form>
              <b-row>
                <b-col>
                  <label>Screenshot Payment:</label>
                  <br />
                  <img :src="selected.screenshot_payment" />
                </b-col>
              </b-row>

              <b-button
                type="button"
                @click="selected = null"
                variant="danger"
                style="margin-right: 20px;"
              >Back</b-button>
              <b-button
                type="button"
                variant="primary"
                @click="active(selected.order_id)"
                style="margin-right: 20px;"
              >Available</b-button>
            </b-form>
          </b-card>

          <v-card>
            <v-card-title>
              Select Professionals
              <b-button
                type="button"
                @click="selectW()"
                variant="primary"
                style="margin-left: 15px"
              >Select</b-button>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search2"
                append-icon="search"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              v-model="selectedWorker"
              :headers="professionals"
              :items="worker"
              item-key="id"
              show-select
              :search="search2"
              style="cursor: pointer;"
            ></v-data-table>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import OrderInfo from "@/view/content/OrderInfo.vue";

export default {
  name: "pending-orders",
  methods: {
    clicked(value) {
      this.selected = value;
      console.log(this.selected);
    },

    selectW() {
      console.log(this.selectedWorker);
    },

    onSubmit(evt) {
      evt.preventDefault();
      alert(JSON.stringify(this.form));
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.name = "";
      this.form.food = null;
      this.form.checked = [];
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    selectProfessional(value) {
      fetch(
        "https://cleancity.krueltech.com/dev/api/api.php?action=selectWorker&jid=" +
          this.selected.order_id +
          "&wid=" +
          value.id
      )
        .then(data => {
          alert("The independent has been selected!");
        })
        .catch(console.log);
    },
    active(order_id) {
      fetch(
        "https://cleancity.krueltech.com/dev/api/api.php?action=activeOrder&id=" +
          order_id
      )
        .then(data => {
          alert("The order has been made available!");
          window.location.reload();
        })
        .catch(console.log);
    }
  },
  components: {
    OrderInfo,
  },
  data() {
    return {
      selectedWorker: [],
      order: [],
      worker: [],
      workerBackup: [],
      orderAtual: null,
      fields: ["name", "city", "address", "type"],
      form: {
        email: "",
        name: "",
        registrationDate: "",
        food: null,
        checked: []
      },
      search: "",
      search2: "",
      selected: null,
      headers: [
        { text: "Order ID", value: "order_id" },
        { text: "Customer", value: "customer" },
        { text: "Date Cleaning", value: "date" },
        { text: "Type", value: "type" },
        { text: "Amount", value: "value" },
        { text: "Amount IND. CON.", value: "value_final" }
      ],
      professionals: [
        { text: "ID", value: "id" },
        { text: "Name", value: "name" },
        { text: "Company", value: "company" }
      ]
    };
  },
  mounted() {
    var auth = localStorage.getItem("name");
    if (auth != null && auth != "undefined") {
      console.log("existe");
    } else {
      console.log("nao existe");
      this.$router.push({ name: "login" });
    }

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vuetify", route: "alerts" },
      { title: "Tables", route: "simple-tables" },
      { title: "Data Tables" }
    ]);

    fetch("https://cleancity.krueltech.com/dev/api/api.php?action=pendingOrders")
      .then(res => res.json())
      .then(data => {
        this.order = data.pending;
      })
      .catch(console.log);

    fetch("https://cleancity.krueltech.com/dev/api/api.php?action=readWorkers")
      .then(res => res.json())
      .then(data => {
        this.worker = data.worker;
        this.workerBackup = data.worker;
      })
      .catch(console.log);
  }
};
</script>
